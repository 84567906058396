import React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { pageFadeInOut } from '@/modules/animationVariants'
import ModuleList from './all-modules/ModuleList'
import { useContentInProp } from '@/hooks/useContentInProp'
import { Left, MobileFooter } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'
import MobileBack from '@/components/MobileBack'

export const query = graphql`
  query allCmsModule {
    allCmsModule(filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          slug
          body
          intro
          title
        }
      }
    }
  }
`

const AllModulesPage: React.FC = ({ data }) => {
  const modules = data.allCmsModule.edges.map(({ node }) => node)
  const inProp = useContentInProp()

  return (
    <>
      <Left>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="left"
            delay={0}
            time={1}
            url="/our-platform/modules"
            text="back"
          />
        )}
      </Left>
      <Page
        content={
          <PageWrapper>
            <Box>
              <ModuleList data={modules} />
            </Box>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
          isSubPage: true,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          alwaysShow
          variant="light"
          delay={0}
          time={1}
          url="/our-platform/modules"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default AllModulesPage
